
.notifications .alert {
  position: fixed;
  z-index: 10000;

  &[data-notify="container"] {
    max-width: 500px;
  }

  &.center {
    margin: 0 auto;
  }
  &.left {
    left: 20px;
  }
  &.right {
    right: 20px;
  }
}
