@use "@stephane888/wbu-atomique/scss/wbu-ressources-clean.scss" as *;
.cmp_b_vuejs_container {
  .card-body {
    padding: $wbu-margin * 0.5;
  }
}

/**
 * Style non cdefinie sur bootstrap5.
 */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
.modal-open {
  overflow: hidden;
}

.form-check-input-custom {
  input {
    margin-right: 1rem;
  }
}
