@use "@stephane888/wbu-atomique/scss/wbu-ressources-clean.scss" as *;
.vuejs-uploader {
  .previews {
    margin-left: -1rem;
    margin-right: -1rem;
    display: flex;
    flex-wrap: wrap;
    margin-left: -$wbu-margin * 0.5;
    margin-right: -$wbu-margin * 0.5;
    .item {
      width: 33%;
      min-width: 100px;
      min-height: 100px;
      padding: $wbu-margin * 0.5;
      position: relative;
      overflow: hidden;
      z-index: 1;
      &.w-alt {
        width: 100%;
      }
      @include media-min(SM) {
        width: 150px;
      }
      img {
        max-height: 100px;
        max-width: 100%;
      }
      .icon-delete {
        position: absolute;
        left: auto;
        right: 10px;
        bottom: auto;
        top: 5px;
        opacity: 0.5;
        background: rgba(0, 0, 0, 0.8);
        border-radius: 50%;
        cursor: pointer;
        z-index: 2;
        border: 1px solid transparent;
        @include WBUtransition();
      }
      &:hover {
        .icon-delete {
          opacity: 0.7;
          background: rgb(255 255 255 / 100%);
          border: 1px solid rgb(0 0 0 / 15%);
          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
}
