@use "../_variables" as *;
@use "@stephane888/wbu-atomique/scss/wbu-ressources-clean.scss" as *;
.statistiques {
  .item {
    margin-right: $wbu-margin * 0.45;
    &:last-child {
      margin-right: 0;
    }
    &-progress {
      min-width: 100px;

      .progress {
        position: relative;
        height: 100%;
        margin: 0;
        padding: 0;
        background: #f8f9fe;
      }
      .custom-label {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(10px, 50%);
      }
    }
  }
}
